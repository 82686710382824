<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Prime Survey
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- Name -->
              <b-form-group
                label="Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    placeholder="Your name"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Company -->
              <b-form-group
                label="Company"
                label-for="register-company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  vid="company"
                  rules="required"
                >
                  <b-form-input
                    id="register-company"
                    v-model="company"
                    name="register-company"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    placeholder="Your company"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                      errors.length > 0
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="
                        errors.length > 0 ? false : null
                      "
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="
                          togglePasswordVisibility
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'page-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      company: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (this.$store.getters['auth/isLoggedIn']) {
      this.$router.push({ paht: '/' })
    }
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-underscore-dangle
          const _this = this
          this.showLoading()
          this.$http
            .post('/api/auth/register', {
              email: this.userEmail,
              password: this.password,
              name: this.name,
              company: this.company,
            })
            .then(response => {
              _this.hideLoading()
              const { data } = response
              if (data.result === true) {
                // _this.$store.dispatch("auth/login", data);
                _this.alertSuccess(data.message)
                setTimeout(() => {
                  _this.$router.push({ path: '/pages/login' })
                }, 1999)
              }
            })
            .catch(error => {
              _this.hideLoading()
              _this.$refs.registerForm.setErrors(
                error.response.data.errors,
              )
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
